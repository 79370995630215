import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { AuthContext } from "./context/AuthContext";
import { callMsGraph } from "./auth/graph";
import Router from "./routes/index";

import "./styles/App.css";
import Home from "./pages/public/Home";



/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <AuthContext>
                    <Router />
                </AuthContext>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Home />
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
