import {useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import { useNavigate } from "react-router-dom";

import { useForm } from "../../hooks/useForm";
const {baseUrlApi,urlLogoIESTP,urlbibliotecaIESTP,colorFondoLogoIESTP,NombreIESTP,correoIESTP} = window;

function Estudiante() {
  const { accounts } = useMsal();
  const [isLoading, setLoading] = useState(false);
  const [object, setValues] = useState({ administrador: 0 });
  let navigate = useNavigate();


    //Funcion que se ejecuta al inicial la pagina
    useEffect(() => {
      async function init() {
        //alert(accounts[0].username)
        try {
          let url = baseUrlApi+'/usuario/' + accounts[0].username;
          fetch(url)
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              //alert(JSON.stringify(json))
              
             if (json=='null'|| json==null){
              navigate(`/sin-permiso`)
             }  
             else{
              if (json.administrador!=1)  navigate(`/sin-permiso`);
             }
            
              
            })
  
        } catch (err) {
          alert(err)
        }
      }
      init();
    }, []);

  const handleInputChange = ({ target }) => {
    let fieldsUpperCase = ["nombres", "apellidos"]
    if (fieldsUpperCase.includes(target.name)) {
      setValues({
        ...object,
        [target.name]: target.value.toUpperCase()
      });
    } else {
      setValues({
        ...object,
        [target.name]: target.value
      });
    }
    console.log(object)
  }

  //Para el cambio de la datos
  const _handleChange = (e) => {
    const { checked, name } = e.target;
    setValues({
      ...object,
      [name]: checked ? 1 : 0
    });

    console.log(object)


  };


  async function handleSubmit(e) {
    setLoading(true)
    e.preventDefault();
    console.log(object)
    try {
      const rawResponse = await fetch(baseUrlApi+'/usuario', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(object)
      });

      const content = await rawResponse.json();
      console.log(content);
      
      if(content.errors) throw content.errors;
      alert("Se registro correctamente el usuario.")
      await enviarCorreo()
      setLoading(false)
      navigate(`/`);
     

    } catch (err) {
      alert('Error!!! -> \n'+JSON.stringify(err))
      setLoading(false)
    } finally {

    }
  }

  const enviarCorreo= async ()=>{

    let templateHTML=`
    <p style="padding-left: 30px; text-align: center;"><img style="background: ${colorFondoLogoIESTP}; padding: 10px; border-radius: 10px;" src="${urlLogoIESTP}" /></p>
<p style="padding-left: 30px; text-align: center;">Bienvenido a la biblioteca Virtual del IESTP ${NombreIESTP}<br />Estimado estudiante, ud ahora tiene acceso al los a las bases de datos de los libros digitales.</p>
<p>Tenga en cuenta los siguiente:</p>
<ol>
<li>Tener correo institucional.</li>
<li>Ingresar al siguiente Link <a href="${urlbibliotecaIESTP}">${urlbibliotecaIESTP}</a></li>
</ol>
    `

    const rawResponse = await fetch(baseUrlApi+'/web/sendemail', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {

          "from": `BIBLIOTECA VIRTUAL -  <${correoIESTP}>`,
          "subject": "ALTA DE ACCESO A BIBLIOTECA VIRTUAL ",
          "email_html": templateHTML,
          "to": object.correo
      }
      )
    });

    const content = await rawResponse.json();
    console.log(content);
  }



  return (
    <>
      <div class="container">
        <Breadcrumb>
          <Breadcrumb.Item >
          
          <Link  to={"/"} >Inicio</Link> 
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Registro de usuario</Breadcrumb.Item>
        </Breadcrumb>
        <Form onSubmit={handleSubmit}>
          <h3>Registro de usuario</h3>
          <hr />
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>DNI <Form.Label className='red'>* </Form.Label></Form.Label>
            <Form.Control required name="dni" value={object.dni} onChange={handleInputChange} type="text" placeholder="Ingrese el DNI" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nombres <Form.Label className='red'>* </Form.Label></Form.Label>
            <Form.Control required name="nombres" value={object.nombres} onChange={handleInputChange} type="text" placeholder="Ingrese el nombres" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Apellidos </Form.Label>   <Form.Label className='red'>* </Form.Label>
            <Form.Control required name="apellidos" value={object.apellidos} onChange={handleInputChange} type="text" placeholder="Ingrese el nombres" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Correo <Form.Label className='red'>* </Form.Label></Form.Label>

            <Form.Control required name="correo" value={object.correo} onChange={handleInputChange} type="email" placeholder="Ingrese correo" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Carrera profesional</Form.Label>
            <select required name="carrera" value={object.carrera} onChange={handleInputChange} class="form-control">

              <option value="0">--Seleccione--</option>
              <option value="Arquitectura de plataformas y servicio de las TIC">Arquitectura de plataformas y servicio de las TIC</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Mecánica Automotriz">Mecánica Automotriz</option>
              <option value="Enfermería técnica">Enfermería técnica</option>
              <option value="Computación e Informática">Computación e Informática</option>
              <option value="Diseño y Programación Web">Diseño y Programación Web</option>
              <option value="Guía Oficial de turismo">Guía Oficial de turismo</option>
              <option value="Industrias Alimentarias">Industrias Alimentarias</option>
              <option value="Producción Agropecuaria">Producción Agropecuaria</option>
              <option value="Construcción civil">Construcción civil</option>
              <option value="Otro">Otro</option>
            </select>
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check name="administrador" value={object.administrador} onClick={_handleChange} type="checkbox" label="Administrador" />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? 'Guardando…' : 'Registrar'}
          </Button>
        </Form>
      </div>

    
        
        

    </>
  );
}

export default Estudiante;