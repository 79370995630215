import React, { useState ,useEffect} from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useLocalStorageContext from "../../utils/useLocalStorageContext";


import ListBook from "../../components/ListBook";
const {baseUrlApi,urlLogoIESTP,urlbibliotecaIESTP,colorFondoLogoIESTP,NombreIESTP,correoIESTP} = window;
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
export const BuscadorLibro = () => {

  const { instance, accounts } = useMsal();
  let navigate = useNavigate();

  //para guardar los datos del usuarios
  const [
    saveStorageEstudiante,
    getStorageEstudiante,
    removeStorageEstudiante
  ] = useLocalStorageContext("Estudiante");

  //Vericaindo el permiso del usuario logueado 

  useEffect(() => {
    async function init() {
      //alert(accounts[0].username)
      try {
        let url = baseUrlApi+'/usuario/' + accounts[0].username;
        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            //alert(JSON.stringify(json))

           
           if (!json )  navigate(`/sin-permiso`)
           else saveStorageEstudiante(json)
            
          })

      } catch (err) {
        alert(err)
      }
    }
    init();
  }, []);


  
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
    });
  }

  return (
    <>

      <div class="container">
        <h1 class="mt-5">Búsqueda de libros</h1>
        <br />
        
      <ListBook/>
      </div>
      {/*graphData ? 
                <ProfileData graphData={graphData} />
                :
                <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
    */ }
    </>
  );
};