import React from "react";
import {  useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Button from "react-bootstrap/esm/Button";
import {  FaSignOutAlt} from 'react-icons/fa';
import { AiOutlineUser} from 'react-icons/ai';

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance, accounts } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <>
            <DropdownButton id="dropdown-basic-button" title= {` ${accounts[0].name}`}>
      <Dropdown.Item onClick={() => handleLogout("popup")} href="#"><FaSignOutAlt/> Cerrar sessión</Dropdown.Item>
      
    </DropdownButton>

       
        
        </>
    )
}