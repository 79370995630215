import React, { useEffect, useState, useMemo,lazy, useContext } from 'react';
import { BrowserRouter, Redirect, Routes, Route, useLocation } from "react-router-dom";
import {MyContext} from "../context/AuthContext";

import { BuscadorLibro } from "../pages/libros/BuscadorLibro";
import { DetalleLibro } from "../pages/libros/DetalleLibro";
import { VisorLibro } from "../pages/libros/VisorLibro";
import Estudiante from "../pages/admin/Estudiante";
import Error404 from "../pages/public/Error404";
import SinPermiso from '../pages/public/SinPermiso';




function Rutas() {


  const { pathname } = useLocation();
  const [user, setUser] = useState();

  const {
    setAuthAdmin
  } = useContext(MyContext);





  return (
    <>

      <Routes>
        
      
          <>
           {setAuthAdmin(true)}
            <Route path="/" element={<BuscadorLibro />}>
            </Route>
            <Route path="/buscador-libro" element={<BuscadorLibro />}>
            </Route>
            <Route path="/detalle-libro/:idlibro/:nombre_libro" element={<DetalleLibro />}>
            </Route>
            <Route path="/registro-estudiante" element={<Estudiante />}>
            </Route>
            <Route path="/lectura-libro/:libro/:url" element={<VisorLibro />}>
            </Route>
            <Route path="/sin-permiso" element={<SinPermiso />}>
            </Route>

            <Route path="*" element={<Error404 />} />
          </>
        
      </Routes>
    </>
  )
}


export default Rutas;