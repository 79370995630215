import React, { useState, useEffect,useRef } from 'react'
import CardLibro from './CardLibro'
import useLocalStorageContext from "../utils/useLocalStorageContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import { FiSearch } from 'react-icons/fi';
const { baseUrlApi, urlLogoIESTP, urlbibliotecaIESTP, colorFondoLogoIESTP, NombreIESTP, correoIESTP } = window;

export default function ListBook() {

    const inputElement = useRef(null);
    const [isLoading, setLoading] = useState(false);

    const [
        saveStorageEstudiante,
        getStorageEstudiante,
        removeStorageEstudiante
    ] = useLocalStorageContext("Estudiante");

    const [listalibros, setlistalibros] = useState([])
    const [cantLibros, setcantLibros] = useState(0)

    useEffect(() => {
        async function init() {
            //alert(accounts[0].username)
            let carrera_estudiante=getStorageEstudiante().carrera||'enfermeria'
            try {
                setLoading(true)
                let url = baseUrlApi + '/libros/' + carrera_estudiante+'/a';
                fetch(url)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (json) {
                        //alert(JSON.stringify(json))
                        setcantLibros(json.length)
                        setlistalibros(json)
                        setLoading(false)

                    })

            } catch (err) {
                alert(err)
            }
        }
        init();
    }, []);

    const buscarLibros=(e)=>{
        setLoading(true)
       let busqueda= inputElement.current.value

       let url = baseUrlApi + '/libros/' + getStorageEstudiante().carrera+'/'+ (busqueda.length==0 ? 'a': busqueda);
       fetch(url)
           .then(function (response) {
               return response.json();
           })
           .then(function (json) {
               //alert(JSON.stringify(json))
               setcantLibros(json.length)
               setlistalibros(json)
               setLoading(false)

           })

        
    }



    return (
        <>


            <InputGroup size="lg">
                <Form.Control
                    aria-label="Large"
                    placeholder="Ingrese la busqueda del libro como el autor, titulo o el codigo ISBN"
                    aria-describedby="inputGroup-sizing-sm"
                    ref={inputElement}
                />
                <div class="input-group-append">
                    <button onClick={buscarLibros} class="btn btn-outline-secondary" type="button" disabled={isLoading}>
                   {!isLoading ? <FiSearch/> : 
                   <div class="spinner-border text-success" role="status">
  <span class="sr-only">Loading...</span>
</div>
}
                    
                    
                     {isLoading ? 'Buscando…' :  'Búscar' }
                        
                        
                        </button>
                </div>

            </InputGroup>
            <p>Puede descargar la lista completa de los libros <a href="/docs/4.6/examples/sticky-footer/">aquí</a> para luego realizar la búsqueda.</p>
            <br />
            <h3>Resultados:{cantLibros} </h3>
            <div class="row mb-2">
                {listalibros.map(libro => (
                    <CardLibro libro={{ ...libro }} />
                ))}
            </div>
        </>
    )
}
