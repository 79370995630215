import React from 'react'
import { Link } from "react-router-dom";
import {  AiOutlineRead} from 'react-icons/ai';



export default function CardLibro({ props, libro }) {

    let urlfotoPortada=libro.portada;

    function add3Dots(string, limit) {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }

    return (
        <>
            <div class="col-md-6">
                <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-primary">{libro.carrera}</strong>
                        <h6 class="mb-0" title={libro.nombre_libro }>{add3Dots(libro.nombre_libro,90) }</h6>
                        <div class="mb-1 text-muted " title={libro.autor}>{add3Dots(libro.autor,45)}</div>
                        <p class="card-text mb-auto"></p>
                        <Link className='btn btn-outline-primary' to={"/detalle-libro/" + libro.id+"/"+libro.nombre_libro}>
                        <AiOutlineRead/> Ver libro</Link>

                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <img src={urlfotoPortada} width="200" height="250"  ></img>

                    </div>
                </div>
            </div>
        </>
    )
}
