import React, { useState ,useEffect} from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link ,useParams} from "react-router-dom";
import DetailsBook from '../../components/DetailsBook'
import useLocalStorageContext from "../../utils/useLocalStorageContext";
import { FiSearch } from 'react-icons/fi';
const {baseUrlApi} = window;

export const DetalleLibro = () => {
  const [libro,setLibro]=useState({portada:'',url_digital:''})
  let { idlibro,nombre_libro } = useParams();

  //guardando los libros
  const [
    saveStorageLibro,
    getStorageLibro,
    removeStorageLibro
  ] = useLocalStorageContext("Libro");

  useEffect(() => {
    async function init() {
      //alert(nombre_libro)
      try {
        let url = baseUrlApi+'/libro/' +idlibro+'/'+nombre_libro;
        //alert(url)
        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            //alert(JSON.stringify(json))
            setLibro(json)
            saveStorageLibro(json)
          })

      } catch (err) {
        alert(err)
      }
    }
    init();
  }, []);

  
  return (
    <>
      <div class="container">
        <Breadcrumb>
          <Breadcrumb.Item > <Link  to={"/"} >Inicio</Link> </Breadcrumb.Item>
          <Breadcrumb.Item active>Detalle del libro</Breadcrumb.Item>
        </Breadcrumb>
       
       <DetailsBook libro={libro}/>

      </div>

    </>
  )
}
