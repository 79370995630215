import React, { useContext } from 'react';
import {MyContext} from "../context/AuthContext";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate} from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { HiOutlineAcademicCap } from 'react-icons/hi';
import Logo from "../img/Logo";
import LogoConsorcioWebAyacucho from "../img/LogoConsorcioWebAyacucho";
const {urlLogoIESTP,colorFondoLogoIESTP,NombreIESTP}=window



/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props) => {

  let navigate = useNavigate();
  const {
    authAdmin
  } = useContext(MyContext);
  const isAuthenticated = useIsAuthenticated();
  //alert(isAuthenticated)

  return (
    <>

      <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">

        <h5 onClick={(e)=>  navigate(`/`)} class="my-0 mr-md-auto font-weight-normal pointer">
           <img className='logoIESTP' style={{"background":colorFondoLogoIESTP}} src={urlLogoIESTP}/> <Logo></Logo> Biblioteca Virtual IESTP {NombreIESTP}
          </h5>
          
        <nav class="my-2 my-md-0 mr-md-3">
          {isAuthenticated  ?   <Link className='p-2 text-dark' to={"/registro-estudiante"} ><HiOutlineAcademicCap></HiOutlineAcademicCap> Registro de estudiante</Link>  : null}
        </nav>

        {isAuthenticated ? <SignOutButton /> : <SignInButton />}

      </div>




      {props.children}
      <div className="container">
        <footer class="pt-4 my-md-5 pt-md-5 border-top center-div">
          <div class="row">
            <div class="col-12 col-md">
              <LogoConsorcioWebAyacucho />
              <small class="d-block mb-3 text-muted">&copy; 2017-2022</small>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
