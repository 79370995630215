import React from 'react'
import { useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { AiFillRead } from 'react-icons/ai';

export default function DetailsBook({ libro }) {
    let navigate = useNavigate();
    let urlfotoPortada = libro.portada;
    let urlfile = libro.url_digital.split('=').pop();

    const leerLibro = (libro,url) => {
       // alert(encodeURI(url))
        navigate(`/lectura-libro/${libro}/${url.length==0?'sin-url': encodeURI(url)}`);
    }
    return (
        <>
            <div class="row mt-4">

                <div class="col-lg-4 text-center border-right border-secondery">
                    <div class="tab-content row h-100 d-flex justify-content-center align-items-center" id="myTabContent">
                        <div class="tab-pane fade show active col-lg-12" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <img class="img-fluid" src={urlfotoPortada} width="300" height="450" />
                        </div>


                    </div>
                    <div className="d-grid gap-2">
                        <button onClick={() => leerLibro(libro.nombre_libro,urlfile)} type="button" class="btn btn-lg btn-block btn-danger"><AiFillRead /> Leer libro</button>

                    </div>
                </div>
                <div class="col-lg-7">
                    <h5>
                        {libro.nombre_libro}
                    </h5>



                    <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    ><Tab eventKey="profile" title="Ficha tecnica">
                            <table class="pull-left col-md-8 font-9">
                                <tbody>
                                    <tr>
                                        <td ><strong>ISBN</strong></td>
                                        <td> </td>
                                        <td >{libro.isbn}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Autor(es)</strong></td>
                                        <td> </td>
                                        <td >{libro.autor}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Editorial</strong></td>
                                        <td> </td>
                                        <td >{libro.editorial}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Nro. páginas</strong></td>
                                        <td> </td>
                                        <td >{libro.nro_paginas}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Año edición</strong></td>
                                        <td> </td>
                                        <td >{libro.anio_edicion}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Nro. edición</strong></td>
                                        <td> </td>
                                        <td >{libro.nro_edicion}</td>
                                    </tr>
                                    <tr>
                                        <td ><strong>Tomo</strong></td>
                                        <td> </td>
                                        <td >{libro.tomo}</td>
                                    </tr>





                                </tbody>
                            </table>
                        </Tab>
                        <Tab eventKey="home" title="Reseña">
                            {libro.resenia}
                        </Tab>


                    </Tabs>


                </div>

            </div>
            <br />
            <br />
        </>
    )
}
