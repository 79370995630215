import { createContext, useEffect, useState } from 'react';
import useLocalStorageContext from "../utils/useLocalStorageContext";

const INIT_VALUE = {}
export const STORAGE_NAME_ADMIN = 'token-admin';

const MyContext = createContext(INIT_VALUE);

const AuthContext = ({ children }) => {
  const [
    saveStorageAdmin,
    getStorageAdmin,
    removeStorageAdmin
  ] = useLocalStorageContext(STORAGE_NAME_ADMIN);


  const [authAdmin, setAuthAdmin] = useState(false);
  const [loadingAuthAdmin, setLoadingAuthAdmin] = useState(false);


  const values = {
    authAdmin,
    setAuthAdmin

  }

  useEffect(() => {

    if (getStorageAdmin()) setAuthAdmin(getStorageAdmin());

  }, [])

  return (
    <MyContext.Provider value={values}>
      {children}
    </MyContext.Provider>
  );
}

export {
  AuthContext,
  MyContext,
};