import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
//import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useLocalStorageContext from "../../utils/useLocalStorageContext";
const { baseUrlApi } = window;

export const VisorLibro = () => {

    let navigate = useNavigate();
    const { accounts } = useMsal();
    const handle = useFullScreenHandle();
    let { libro, url } = useParams();
  

    const [
        saveStorageLibro,
        getStorageLibro,
        removeStorageLibro
      ] = useLocalStorageContext("Libro");
      const [fileURL,setFileURL]= useState("");

    //Funcion que se ejecuta al inicial la pagina
   useEffect(() => {
        async function init() {
            //alert(accounts[0].username)
            try {
                let url2 = baseUrlApi + '/usuario/' + accounts[0].username;
                fetch(url2)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (json) {
                        //alert(JSON.stringify(json))

                        if (!json )  navigate(`/sin-permiso`)


                    })

            } catch (err) {
                alert(err)
            }
        }
        init();
    }, []);
    


    return (
        <>
            <div class="container">

                <Breadcrumb>
                    <Breadcrumb.Item > <Link to={"/"} >Inicio</Link> </Breadcrumb.Item>
                    <Breadcrumb.Item active>Lectura del libro: <b>{libro}</b></Breadcrumb.Item>
                </Breadcrumb>

                <button onClick={handle.enter} title="Mostrar pantalla completa.">
                    Pantalla completa (F11 o ESC para cerrar)
                </button>
                <FullScreen handle={handle}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src={"https://drive.google.com/uc?id="+url}
                            allowFullScreen></iframe>

                    </div>
                </FullScreen>

            </div>

        </>
    )
}
