import React from 'react'
import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <>
    <div class="error-content">
    <div class="container center-div">
        <div class="row">
            <div class="col-md-12 ">
          <br/>
          <br/>
          <br/>
          <br/>
                <div class="error-text">
                    <h1 class="error">404 Error</h1>
                    <div class="im-sheep">
                        <div class="top">
                            <div class="body"></div>
                            <div class="head">
                                <div class="im-eye one"></div>
                                <div class="im-eye two"></div>
                                <div class="im-ear one"></div>
                                <div class="im-ear two"></div>
                            </div>
                        </div>
                        <div class="im-legs">
                            <div class="im-leg"></div>
                            <div class="im-leg"></div>
                            <div class="im-leg"></div>
                            <div class="im-leg"></div>
                        </div>
                    </div>
                    <h4>Oops! La página que intenta ingresar no existe</h4>
                    
                    <Link className='btn btn-warning btn-round' to={"/"} >Ir al inicio</Link> 
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</div></>
  )
}
