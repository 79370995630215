import React from 'react'

function Logo() {
    return (
        <>
            <svg width="30" height="30" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1607_3862)">
                    <path d="M54.8981 39.7002L62.1981 47.3002L30.0981 63.4002C30.0981 63.4002 25.8981 65.5002 23.8981 62.2002C15.8981 49.2002 54.8981 39.7002 54.8981 39.7002" fill="#256382" />
                    <path d="M29.1991 53.8998C29.1991 53.8998 23.0991 56.1998 24.1991 60.4998C25.3991 64.9998 30.2991 62.2998 30.2991 62.2998L60.7991 47.2998C60.7991 47.2998 59.0991 42.4998 62.1991 39.2998L29.1991 53.8998" fill="#D9E3E8" />
                    <path d="M34.4 8.8999L63.6 38.9999L29.1 53.2999L7 16.6999L34.4 8.8999Z" fill="#42ADE2" />
                    <path d="M60.6984 42.6002L40.2984 51.4002L60.2984 41.7002L60.6984 42.6002ZM60.3984 45.2002L38.6984 54.7002L59.9984 44.3002L60.3984 45.2002ZM60.5984 46.7002L32.8984 59.4002L60.1984 45.8002L60.5984 46.7002Z" fill="#94989B" />
                    <path d="M23.8 62.0998C20.4 54.5998 29.1 53.2998 29.1 53.2998L7 16.6998C7 16.6998 2 16.5998 2 22.0998C2 24.3998 3 26.0998 3 26.0998L23.8 62.0998Z" fill="#428BC1" />
                    <path d="M8.69844 32.2002L1.39844 39.8002L33.4984 55.9002C33.4984 55.9002 37.6984 58.0002 39.6984 54.7002C47.6984 41.7002 8.69844 32.2002 8.69844 32.2002" fill="#547725" />
                    <path d="M34.2984 46.3998C34.2984 46.3998 40.3984 48.6998 39.2984 52.9998C38.0984 57.4998 33.2984 54.7998 33.2984 54.7998L2.79844 39.7998C2.79844 39.7998 4.49844 34.9998 1.39844 31.7998L34.2984 46.3998Z" fill="#D9E3E8" />
                    <path d="M29.2 1.3999L0 31.4999L34.5 45.7999L56.6 9.1999L29.2 1.3999Z" fill="#83BF4F" />
                    <path d="M3.20078 34.2002L23.2008 43.9002L2.80078 35.1002L3.20078 34.2002ZM3.60078 36.8002L24.8008 47.2002L3.10078 37.7002L3.60078 36.8002ZM3.40078 38.3002L30.6008 51.9002L2.90078 39.2002L3.40078 38.3002Z" fill="#94989B" />
                    <path d="M39.8 54.5998C43.2 47.0998 34.5 45.7998 34.5 45.7998L56.6 9.19983C56.6 9.19983 61.6 9.09983 61.6 14.5998C61.6 16.8998 60.6 18.5998 60.6 18.5998L39.8 54.5998Z" fill="#699635" />
                    <path d="M56.6985 26L62.7985 32.4L35.6985 45.9C35.6985 45.9 32.0985 47.6 30.3985 44.9C23.7985 34 56.6985 26 56.6985 26Z" fill="#962C2C" />
                    <path d="M34.9996 38.0002C34.9996 38.0002 29.7996 39.9002 30.7996 43.6002C31.7996 47.4002 35.8996 45.1002 35.8996 45.1002L61.5996 32.4002C61.5996 32.4002 60.1996 28.4002 62.7996 25.7002L34.9996 38.0002Z" fill="#D9E3E8" />
                    <path d="M39.3992 0L63.9992 25.4L34.8992 37.5L16.1992 6.6L39.3992 0Z" fill="#ED4C5C" />
                    <path d="M40.1 5.7998L44.9 11.0998L27.2 17.7998L23 10.9998L40.1 5.7998Z" fill="white" />
                    <path d="M61.5992 28.5001L44.3992 35.8001L61.1992 27.6001L61.5992 28.5001ZM61.3992 30.7001L42.9992 38.6001L60.9992 29.8001L61.3992 30.7001ZM61.5992 31.9001L38.1992 42.6001L61.0992 31.0001L61.5992 31.9001Z" fill="#94989B" />
                    <path d="M30.4984 44.8998C27.6984 38.5998 34.9984 37.4998 34.9984 37.4998L16.1984 6.59978C16.1984 6.59978 11.8984 6.49978 11.8984 11.0998C11.8984 12.9998 12.6984 14.4998 12.6984 14.4998L30.4984 44.8998Z" fill="#C94747" />
                </g>
                <defs>
                    <clipPath id="clip0_1607_3862">
                        <rect width="64" height="64" fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </>
    )
}

export default Logo